<template>
  <v-app>   

    <v-main>
      <v-container class="fill-height container-background" fluid></v-container>
      <v-container 
            class="fill-height justify-center" 
            fluid
        >
           <v-row 
            class="text-center"
            style="z-index:10"
          >
              <v-col cols="12">
                <v-img
                  :src="require('./assets/logo.png')"
                  class="my-3"
                  contain
                  height="260"
                />
              </v-col>

              <v-col cols="12" class="mb-4">
                <h1 class="primary--text display-2 font-weight-bold mb-3">
                  Welcome to ASJA Girls' College San Fernando
                </h1>

                <p class="display-1 font-weight-light primary--text">
                  Student and Teacher Online Portal
                </p>  
              </v-col>

              <v-col cols="12">
                <h2 class="primary--text font-weight-light font-italic">
                  Select the link to get started
                </h2>
              </v-col>

              <v-col
                cols="12"
                md="2"
                offset-md="3"
                
              >
                  <v-btn
                    color="primary"
                    dark
                    large
                    href="https://student-portal.asjagirlscollegesanfernando.net"                    
                  >Student Portal</v-btn>
              </v-col>  
              <v-col
                cols="12"
                md="2"
              >
                  <v-btn
                    color="primary"                    
                    outlined
                    large
                    href="https://term-reports.asjagirlscollegesanfernando.net" 
                  >Teacher Portal</v-btn>
              </v-col>  
              <v-col
                cols="12"
                md="2"
              >
                  <v-btn
                    color="secondary"
                    dark
                    large
                     href="https://term-reports.asjagirlscollegesanfernando.net/admin"                     
                  >
                    <span class="primary--text">Admin Portal</span>
                  </v-btn>
              </v-col>  
           </v-row>
        </v-container>
      
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
    
  },

  data: () => ({
    schoolName: "",
  }),
};
</script>

<style scoped>
    .container-background{
        /* background-image: url('./assets/background.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        filter: brightness(40%); */
        position: absolute;        
    }
       
</style> 
